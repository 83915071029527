import type { FC } from 'react';
import React, { useContext } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { cssMap } from '@compiled/react';

import type { LinkItemProps } from '@atlaskit/menu';
import { LinkItem } from '@atlaskit/menu';
import Tooltip from '@atlaskit/tooltip';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { SPACE_CALENDARS } from '@confluence/named-routes';
import { LoadableAfterPaint, useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { PremiumLozengeLoadable } from '@confluence/trial-components';
import { usePageSpaceKey } from '@confluence/page-context';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { SPAViewContext } from '@confluence/spa-view-context';

const styles = cssMap({
	root: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			padding: `0px ${token('space.050')} 0px ${token('space.100')}`,
			height: '36px',
			minHeight: '36px',
			borderRadius: '3px',
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
			fontSize: '14px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			span: {
				columnGap: token('space.100'),
			},
			backgroundColor: 'transparent',
			'&:hover': {
				backgroundColor: token('color.background.neutral.subtle.hovered'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
			'&, &:visited, &:active, &:focus': {
				color: token('color.text.subtle'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
			textDecoration: 'none !important',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'[data-item-elem-before=true]': {
				marginRight: '0px',
				height: '24px',
				width: '24px',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
				'img, span, svg': {
					height: '20px',
					width: '20px',
				},
			},
		},
	},
	isSelected: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			backgroundColor: token('color.background.selected'),
			'&:hover': {
				backgroundColor: token('color.background.selected.hovered'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
			'&, &:visited, &:active, &:focus': {
				color: token('color.text.selected'),
			},
		},
	},
});

const listStyles = xcss({
	marginTop: 'space.0',
});

const CalendarIcon = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CalendarIcon" */ './img/CalendarIcon'))
			.CalendarIcon,
});

const i18nTooltips = defineMessages({
	calendarsTooltip: {
		id: 'side-navigation.space-navigation.calendars.tooltip',
		defaultMessage: 'View calendar',
		description: 'Tooltip message on hover of calendars space link',
	},
});

type TeamCalendarNavigationProps = Pick<LinkItemProps, 'isSelected' | 'onClick'>;

const TeamCalendarNavigationComponent: FC<TeamCalendarNavigationProps> = ({
	isSelected,
	onClick,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const [spaceKey] = usePageSpaceKey();

	return (
		<Tooltip position="top" content={<FormattedMessage {...i18nTooltips.calendarsTooltip} />}>
			{(tooltipProps) => (
				<Box as="li" xcss={listStyles}>
					<LinkItem
						css={[styles.root, isSelected && styles.isSelected]}
						key="calendars"
						iconBefore={<CalendarIcon isSelected={isSelected} />}
						isSelected={isSelected}
						href={SPACE_CALENDARS.toUrl({ spaceKey })}
						{...tooltipProps}
						onClick={onClick}
						testId="space-navigation-calendars-link"
						UNSAFE_shouldDisableRouterLink
						data-vc="team-calendar-navigation"
						{...ssrPlaceholderIdProp}
					>
						<FormattedMessage
							id="side-navigation.container.calendars"
							defaultMessage="Calendars"
							description="Calendars Menu Item"
						/>
						<PremiumLozengeLoadable />
					</LinkItem>
				</Box>
			)}
		</Tooltip>
	);
};

export const TeamCalendarNavigation = (props: TeamCalendarNavigationProps) => {
	const { edition } = useSessionData();
	const { isSiteAdmin } = useContext(SPAViewContext);

	// Please also update getPremiumFeaturesWebItemKeys() in SpaceNavigation if this condition is updated
	/**
	 * showTCNav:
	 * - Premium -> true, TC is a premium feature
	 * - Standard -> true, iff user is Org/Site Admin, otherwise, false (https://hello.atlassian.net/wiki/spaces/CE2/pages/3031965072/ETC+Standard+P18N+Technical+Spec)
	 * - Free -> true, TC upsell to all Free users (https://hello.atlassian.net/wiki/spaces/CE2/pages/2906634153/ETC+P18N+Technical+Spec)
	 */
	const showTCNav = edition === ConfluenceEdition.STANDARD ? isSiteAdmin : true;
	return showTCNav ? <TeamCalendarNavigationComponent {...props} /> : null;
};
